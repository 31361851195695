window.gPlantData = {
 amaranth: { latin: "Amaranthus retroflexus", img: "" },
 angelica: { latin: "A. archangelica syn. A. officinalis" },
 anise: { latin: "Pimpinella anisum" },
 apple: { latin: "Malus sylvestris", img: "" },
 apricot: { latin: "Prunus armenaica", img: "" },
 asparagus: { latin: "Asparagus officinalis", img: "" },
 aubergine: { latin: "Solanum melongena", img: "" },
 barley: { latin: "Hordeum vulgare" },
 basil: { latin: "Ocimum basilicum", img: "" },
 bay: { latin: "Laurus nobilis", img: "" },
 beet: { latin: "Beta vulgaris", img: "" },
 bergamot: { latin: "Monarda didyma" },
 blackberry: { latin: "Rubus ulmifolius", img: "" },
 blueberry: { latin: "Vaccinium", img: "" },
 borage: { latin: "Borago officinalis", img: "" },
 broad_bean: { latin: "Vicia faba", img: "" },
 broccoli: { latin: "B. oleracea botrytis", img: "" },
 brussels_sprouts: { latin: "B. oleracea gemmifera", img: "" },
 cabbage: { latin: "B. oleracea capitata", img: "" },
 cannabis: { latin: "Cannabis sativa" },
 caraway: { latin: "Carum Carvi" },
 carrot: { latin: "Daucus carota", img: "" },
 cauliflower: { latin: "B. oleracea botrytis", img: "" },
 celery: { latin: "Apium graveloens", img: "" },
 chamomile: { latin: "Chamaemelum nobile syn. Anthemis nobilis", img: "" },
 cherry: { latin: "Prunus avium", img: "" },
 chervil: { latin: "Anthriscus cerefolium" },
 chilli_pepper: { latin: "Capsicum frutescens", img: "" },
 chinese_cabbage: { latin: "B. rapa pekinensis", img: "" },
 chives: { latin: "A. schoenoprasum" },
 chrysanthemum: { latin: "Chrysanthemum" },
 citrus_fruits: { latin: "Citrus", img: "" },
 pole_bean: { latin: "Phaseolus vulgaris", img: "" },
 columbine: { latin: "Aquilegia" },
 comfrey: { latin: "Symphytum officinale" },
 coriander: { latin: "Coriandrum sativum" },
 corncockle: { latin: "Agrostemma githago" },
 cranberry: { latin: "Vaccinium oxycoccus" },
 cucumber: { latin: "Cucumis sativus", img: "" },
 currant: { latin: "Ribes", img: "" },
 dill: { latin: "Anethum graveolens" },
 dwarf_french_bean: { latin: "Phaseolus vulgaris" },
 fennel: { latin: "Foeniculum vulgare", img: "" },
 fig: { latin: "Ficus", img: "" },
 flax: { latin: "Linum usitatissimum" },
 foxglove: { latin: "Digitalis" },
 garlic: { latin: "A. sativum", img: "" },
 ginger: { latin: "", img: "" },
 gladiolus: { latin: "Gladiolus" },
 globe_artichoke: { latin: "Cynara scolymus", img: "" },
 gooseberry: { latin: "Ribes uva-crispi", img: "" },
 grape: { latin: "Vitis vinifera" },
 horehound: { latin: "Marrubium vulgare" },
 horseradish: { latin: "Armoracia rusticana", img: "" },
 hyssop: { latin: "Hyssopus officinalis" },
 jerusalem_artichoke: { latin: "Helianthus tuberosus", img: "" },
 kale: { latin: "B. oleracea acephela", img: "" },
 kohlrabi: { latin: "B. oleracea gongylodes" },
 lavender: { latin: "Lavandula angustifolia" },
 leek: { latin: "A. porrum syn A. ampeloprasum", img: "" },
 lemon_balm: { latin: "Melissa officinalis", img: "" },
 lettuce: { latin: "Lactuca sativa", img: "" },
 lovage: { latin: "Levisticum officinale" },
 marigold: { latin: "Tagetes" },
 marjoram: { latin: "Marjorana hortensis syn. Origanum marjorana" },
 marrow: { latin: "Cucurbita pepo", img: "" },
 melon: { latin: "Cucumis melo" },
 mint: { latin: "Mentha spp." },
 morning_glory: { latin: "Ipomoea" },
 mulberry: { latin: "Morus nigra" },
 mustard: { latin: "B. nigra" },
 nasturtium: { latin: "Tropaeolum majus", img: "" },
 nectarine: { latin: "Prunus persica var. nectarina", img: "" },
 oats: { latin: "Avena fatua" },
 oca: { latin: "Oxalis tuberosa" },
 okra: { latin: "Abelmoschus esculentus" },
 onion: { latin: "Allim cepa", img: "" },
 parsley: { latin: "Petroselinum hortense" },
 parsnip: { latin: "Pastinaca sativa", img: "" },
 pea: { latin: "Pisum sativum", img: "" },
 peach: { latin: "Prunus persica syn. Persica vulgaris", img: "" },
 peanut: { latin: "Arachis hypogaea", img: "" },
 pear: { latin: "Pyrus communis", img: "" },
 plum: { latin: "Prunus domestica", img: "" },
 potato: { latin: "Solanum tuberosum", img: "" },
 pumpkin: { latin: "Cucurbita maxima", img: "" },
 quince: { latin: "Cydonia vulgaris", img: "" },
 radish: { latin: "Raphanus sativus", img: "" },
 raspberry: { latin: "Rubus idaeus", img: "" },
 rhubarb: { latin: "Rheum rhaponticum" },
 rose: { latin: "Rosa", img: "" },
 rosemary: { latin: "Rosmarinus officinalis", img: "" },
 rue: { latin: "Ruta graveolens" },
 sage: { latin: "Salvia officinalis" },
 salsify: { latin: "Tragopogon porrifolius" },
 scorzonera: { latin: "Scorzonera hispanica" },
 shallot: { latin: "A. cepa aggregatum" },
 southernwood: { latin: "Artemisia abrotanum" },
 soya_bean: { latin: "Glycine max" },
 spinach: { latin: "Spinacia oleracea", img: "" },
 strawberry: { latin: "Fragaria", img: "" },
 summer_savory: { latin: "Satureja hortensis" },
 sunflower: { latin: "H. tuberosus annuus" },
 swede: { latin: "B. napus" },
 sweet_pea: { latin: "Lathyrus odoratus" },
 sweet_pepper: { latin: "Capsicum annuum", img: "" },
 sweetcorn: { latin: "Zea mays" },
 swiss_chard: { latin: "Beta vulgaris cicula", img: "" },
 tansy: { latin: "Tanacetum vulgare" },
 taro: { latin: "" },
 tarragon: { latin: "Artemisia dracunculus" },
 thyme: { latin: "Thymus spp." },
 tomato: { latin: "Lycopersicon esculentum", img: "" },
 turnip: { latin: "B. campestris syn. B. rapa rapifera" },
 valerian: { latin: "Valeriana officinalis" },
 walnut: { latin: "Juglans regia", img: "" },
 watermelon: { latin: "Citrullus lanatus" },
 wheat: { latin: "Triticum aestivum" },
 wormwood: { latin: "Artemisia absinthium" },
 yam: { latin: "Dioscorea alata" },
 yarrow: { latin: "Achillea" },
 lentil: { latin: "Lens culinaris" },
 stinging_nettle: { latin: "" },
 tagetes: { latin: "Tagetes" },
 field_salad: { latin: "Valerianella" } }
